<template>
  <div>
    <sm-loader v-if="getLoanData.loading" />

    <div class="border border-solid border-blue-200">
      <datatable
        :url="`${this.$baseurl}/admin/personal/cards/debit-transactions`"
        :ajax="true"
        :ajax-pagination="true"
        :index="true"
        :reverse-index="true"
        :data="transactions.data"
        :columns="transactions.columns"
        :query="searchQuery"
        :loading="transactions.loading"
        :on-click="click"
        :selectable="true"
        :exportable="true"
        :search-field="searchField"
        :rangeable="dateRange"
        :limit="30"
        ref="table"
        @selection-update="selectionUpdate"
      />
    </div>

    <div
      class="fixed bottom-20 right-5 sm:right-20 ml-5 sm:mr-20 rounded-lg bg-white p-6 z-10 shadow-lg max-w-500px"
    >
      <div class="text-sm text-gray-700 mb-4">
        {{ selection.length }} User{{ selection.length !== 1 ? 's' : '' }}
        Selected!
      </div>

      <template v-if="getFormError(form)">
        <div class="alert alert-red-soft mb-4">
          <span class="alert-icon">!</span>
          <span>{{ getFormError(form) }}</span>
        </div>
      </template>

      <form-group
        type="select"
        :options="lenderOptions"
        left-icon="business-outline"
        name="lender"
        :form="form"
        :loading="lenders.loading"
        v-model="form.data.lender.value"
      >
        Select Lender
      </form-group>
      <form-group
        name="tenure"
        type="number"
        :form="form"
        v-model="form.data.tenure.value"
        :auto-focus="true"
      >
        {{ form.data.tenure.value == 1 ? 'Month' : 'Months' }}
      </form-group>

      <form-group
        name="interest"
        type="number"
        :form="form"
        v-model="form.data.interest.value"
        :auto-focus="true"
      >
        Interest Rate
      </form-group>

      <div>
        <button
          class="btn btn-blue btn-sm mr-3"
          @click.prevent="sendToTangerineLife"
          :disabled="
            form.loading ||
              !selection.length ||
              !form.data.lender.value ||
              !form.data.tenure.value
          "
        >
          <ion-icon
            name="checkmark-done-outline"
            class="text-lg mr-2"
          ></ion-icon>
          <span v-if="form.loading">Marking</span>
          <span v-else>Send to TangerineLife</span>
        </button>
        <button
          class="btn btn-gray btn-sm"
          @click.prevent="clear"
          :disabled="form.loading || !selection.length"
        >
          <ion-icon name="close-circle-outline" class="text-lg mr-2"></ion-icon>
          <span>Clear Selection</span>
        </button>
      </div>
    </div>

    <modals-user
      ref="viewModal"
      :selected-user="selectedUser"
      :allow-delete="false"
    />
  </div>
</template>

<script>
export default {
  props: {
    searchQuery: {
      type: String,
      default: ''
    },
    searchField: {
      type: [HTMLInputElement],
      default: null
    }
  },
  data() {
    return {
      selection: [],
      checkmark: require('@/assets/checkmark-base.svg'),
      lenders: this.$options.resource([]),
      transactions: this.$options.resource([], {
        data: [],
        selected: null,
        columns: [
          {
            name: "user",
            th: "User",
            render: (transaction) => {
              if (!transaction.personal_account) {
                return "N/A";
              }
              return `${transaction.personal_account?.user?.name} ${transaction
                .personal_account?.user?.last_name || ""}`;
            },
          },
          {
            name: "credit_card_limit",
            th: "Credit Limit",
            render: (transaction) => {
              if (transaction?.personal_account?.credit_card_limit) {
                return `<span class="text-blue-500">₦ ${this.$options.filters.currency(
                  transaction.personal_account.credit_card_limit
                )}</span>`;
              }
              return '<span class="text-gray-500">₦ 0.00</span>';
            },
          },
          {
            name: "company",
            th: "Company",
            render: (transaction) =>
              `${transaction?.personal_account?.user?.company?.name || ""}`,
          },
          {
            name: "amount",
            th: "Amount",
            render: (transaction, amount) => {
              if (amount) {
                return `<span class="text-blue-500">₦ ${this.$options.filters.currency(
                  amount
                )}</span>`;
              }
              return '<span class="text-gray-500">₦ 0.00</span>';
            },
          },
        ],
        successes: [],
        failures: []
      }),
      dateRange: {
        value: {
          startDate: null,
          endDate: null,
        },
        format: "dd/mm/yyyy",
      },
      form: this.$options.basicForm(['lender', 'tenure', { name: 'interest', value: null, rules: 'nullable' }]),
      getLoanData: this.$options.resource({})
    };
  },
  computed: {
    banks() {
      return this.$store.state.banks;
    },
    lenderOptions() {
      let options = [];
      this.lenders.data?.forEach(lender => {
        if (lender.name == 'TangerineLife') {
          options.push({
            title: lender.name,
            value: lender.id
          });
        }
      });
      return options;
    },
    selectedUser() {
      return this.transactions.selected?.user;
    }
  },
  beforeMount() {
    this.getLenders();
  },
  mounted() {
    this.$options.listenForClick.apply(this);
  },
  methods: {
    click(transaction) {
      this.transactions.selected = transaction?.personal_account;
      this.$refs.viewModal.open();
    },
    clear() {
      this.$refs.table.clearSelection();
    },
    selectionUpdate({ selection }) {
      this.selection = selection;
    },
    getTransactions() {
      this.$refs.table.loadAjaxData();
    },
    async getLenders() {
      this.lenders.loading = true;
      await this.sendRequest('admin.lenders.all', {
        success: response => {
          this.lenders.data = response.data.lenders;
        }
      });
      this.lenders.loading = false;
    },
    async sendToTangerineLife() {
      if (!this.validateForm(this.form)) {
        return false;
      }

      this.transactions.successes = [];
      this.transactions.failures = [];

      this.form.loading = true;
      this.form.error = null;
      await this.sendRequest('admin.cards.transactions.sendToTangarine', {
        data: {
          accounts: this.selection.map(item => item.row.personal_card_accounts_id),
          lender: this.form.data.lender.value,
          tenure: this.form.data.tenure.value,
          interest: this.form.data.interest.value,
        },
        success: () => {
          this.$success({
            title: 'Transactions Sent to Tangerine',
            body: 'You will be sent an email if there was an error'
          });
          this.clear();
          this.getTransactions();
        },
        error: error => {
          this.form.error = error;

          if (error?.response?.data?.failures) {
            this.transactions.failures = error?.response?.data?.failures;
          }
        }
      });
      this.form.loading = false;
    }
  }
};
</script>
